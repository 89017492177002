<template>
  <AppPage
    container
    title="Notification"
    :loading="loading.single_notification"
  >
    <div class="notification-container px-4" v-if="notification">
      <v-avatar
        size="70"
        :color="!notification.from_user.avatar ? 'primary' : 'transparent'"
        v-ripple
      >
        <img
          v-if="notification.from_user.avatar"
          :src="notification.from_user.avatar"
          :alt="notification.from_user.name"
        />

        <span v-else class="white--text text-h5 uppercase">{{
          notification.from_user.name.substring(0, 2)
        }}</span>
      </v-avatar>

      <h3 class="title">{{ notification.title }}</h3>
      <p class="body">{{ notification.body }}</p>
    </div>
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("notification");
export default {
  name: "notification-detail",
  data() {
    return {};
  },
  computed: {
    ...mapState(["loading", "notification"])
  },
  methods: {
    ...mapActions(["FETCH_SINGLE_NOTIFICATION"])
  },
  created() {
    this.FETCH_SINGLE_NOTIFICATION(this.$route.params.id);
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  font-weight: bold;
  margin: 1.3rem 0;
}

.body {
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  opacity: 0.8;
}
</style>
